import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense, useEffect, useState, lazy } from "react";
//@ts-ignore
import AOS from "aos";
//@ts-ignore
import { ToastContainer } from "react-toastify";
import { Header, Footer, Loader, ErrorPage } from "@gogeepernpm/storybook/lib";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducerState } from "./redux/IRootReducer";
import { INITIALIZE } from "./redux/auth/auth.action";
import { useGlobalAuth } from "./hooks/useGlobalAuth";

const Homepage = lazy(() => import("./pages"));
const Blog = lazy(() => import("./pages/blog"));

function App() {
  const dispatch = useDispatch();
  const { isInitialized, isAuthenticated } = useSelector(
    (state: IRootReducerState) => state.auth
  );

  const { triggerLogout, triggerSignUp, triggerlogin } = useGlobalAuth();

  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleItemClick = (index: number) =>
    activeIndex === index ? setActiveIndex(-1) : setActiveIndex(index);
  const toggleSidebar = () => setOpen(!open);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const contact = () => window.Tawk_API.maximize();
  useEffect(() => {
    dispatch(INITIALIZE());
  }, [isInitialized]);

  return (
    <div className="App">
      <Suspense fallback={<Loader loading={true} />}>
        <Header
          sidebarOpen={open}
          toggleSidebarOpen={toggleSidebar}
          activeIndex={activeIndex}
          onClick={handleItemClick}
          isLoggedIn={isAuthenticated}
          triggerLogin={triggerlogin}
          onTalkToUsClick={contact}
          triggerSignup={triggerSignUp}
          triggerLogout={triggerLogout}
        />
        <ToastContainer />
        <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/blog/:id" element={<Blog />} />
            <Route
              path="*"
              element={
                <ErrorPage onContact={contact} onSignUp={triggerSignUp} />
              }
            />
          </Routes>
        </Router>
        <Footer onTalkToUsClick={contact} />
      </Suspense>
    </div>
  );
}

export default App;
